
.App{
  margin: auto;
  /* border: 2px solid blue; */
  height: 100vh;
}

.homeBackground{
  /* border: 5px solid palevioletred; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* body{
  scrollbar-width: none;
} */
body::-webkit-scrollbar-track
{
    width: 0px;
    height: 0px;
    box-shadow: inset 0 0 0rem #BCE5B9;
    -webkit-box-shadow: inset 0 0 0rem #BCE5B9;
}

body::-webkit-scrollbar
{
    width: 0px;
    height: 0px;
    background-color: #BCE5B9;
}

body::-webkit-scrollbar-thumb
{
    width: 0px;
    height: 0px;
    background-color: #BCE5B9;
}

h1{
  text-align: center;
  font-size: 3rem;
  font-family: 'Gruppo', cursive;
}

h2{
  font-family: 'Gruppo', cursive;
}

h3, h4, h5, h6 {
  font-family: 'Gruppo', cursive;
}

p{
  font-family: 'Baloo 2', cursive;
}

a{
  font-family: 'Baloo 2', cursive;
}

button{
  cursor: pointer;
  border: 1px solid #134818;
  border-radius: 1rem;
  background-color: #BCE5B9;
}

